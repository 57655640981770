<template>

    <section class="sectionMission">

      <v-container fluid>
        <v-row class="align-center paddingAll">

              <v-col cols="12" class="pb-0 pt-0">
                       <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" >{{ $t('mission')}} </div>
            </v-col>
            <v-col cols="12"  class="pb-0 pt-0"  v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" >
                       <div>{{ $t('mission-txt')}} </div>  
            </v-col>
          
        </v-row>
        <v-row class="align-center pink-bg paddingAll">

              <v-col cols="12" class="pb-0 pt-0">
                       <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" > {{ $t('vision')}}</div>
            </v-col>
            <v-col cols="12"  class="pb-0 pt-0" v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" >
                       <div>{{ $t('vision-txt')}} </div>
            </v-col>
          
        </v-row>
                <v-row class="align-center paddingAll">

              <v-col cols="12" class="pb-0 pt-0">
                       <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" > {{ $t('values')}}</div>
            </v-col>
            <v-col cols="12"  class="pb-0 pt-0" v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" >
                       <div>{{ $t('mission-txt')}}</div>  
            </v-col>
          
        </v-row>
     <section class="sectionvission paddingAll">
       <div class="pattern">
       </div>
                <v-row  >

              <v-col md="3" cols="12" class=" text-center">
                          <img class="about-img"  src="@/assets/mission1.png" alt="img" />
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' subboldTxtAr' : 'subboldTxtEn'" >{{ $t('Historical')}} </div>
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' greenTxtAr' : 'greenTxtEn'" ><span v-bind:class="$i18n.locale === 'ar' ?' pinkTxtAr' : 'pinkTxtEn'" > {{ $t('Pride')}}</span> {{ $t('For Inspiration')}} </div>
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" >{{ $t('Historical-txt')}} </div>



            </v-col>
            <v-col md="3" cols="12" class="text-center">
                        <img class="about-img"  src="@/assets/mission2.png" alt="img" />
                         <div v-bind:class="$i18n.locale === 'ar' ?' subboldTxtAr' : 'subboldTxtEn'" >{{ $t('Heritage')}} </div>
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' greenTxtAr' : 'greenTxtEn'" ><span v-bind:class="$i18n.locale === 'ar' ?' pinkTxtAr' : 'pinkTxtEn'" >{{ $t('Passion')}}</span> {{ $t('For Excellence')}}</div>
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" >{{ $t('Heritage-txt')}} </div>

                                   </v-col>
             <v-col md="3" cols="12" class="text-center">
            <img class="about-img"  src="@/assets/mission3.png" alt="img" />
                                  <div v-bind:class="$i18n.locale === 'ar' ?' subboldTxtAr' : 'subboldTxtEn'" > {{ $t('Family')}}</div>
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' greenTxtAr' : 'greenTxtEn'" ><span v-bind:class="$i18n.locale === 'ar' ?' pinkTxtAr' : 'pinkTxtEn'" >{{ $t('Commitment')}}</span> {{ $t('For Well-being')}}</div>
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" > {{ $t('Family-txt')}}</div>
          </v-col>
           <v-col md="3" cols="12" class="text-center">
            <img class="about-img"  src="@/assets/mission4.png" alt="img" />
                                  <div v-bind:class="$i18n.locale === 'ar' ?' subboldTxtAr' : 'subboldTxtEn'" >{{ $t('Child')}} </div>
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' greenTxtAr' : 'greenTxtEn'" ><span v-bind:class="$i18n.locale === 'ar' ?' pinkTxtAr' : 'pinkTxtEn'" >{{ $t('Responsibility')}}</span> {{ $t('For Empowerment')}} </div>
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" >{{ $t('Child-txt')}}</div>
          </v-col>
        </v-row>
                <v-row >

              <v-col md="3" cols="12" class=" text-center">
                          <img class="about-img"  src="@/assets/mission5.png" alt="img" />
                                                            
                                                                                   <div v-bind:class="$i18n.locale === 'ar' ?' subboldTxtAr' : 'subboldTxtEn'" >{{ $t('Educational')}} </div>

                                                 <div v-bind:class="$i18n.locale === 'ar' ?' greenTxtAr' : 'greenTxtEn'" ><span v-bind:class="$i18n.locale === 'ar' ?' pinkTxtAr' : 'pinkTxtEn'" >{{ $t('knowledge')}}</span> {{ $t('For Success')}}</div>
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" >{{ $t('Educational-txt')}}</div>



            </v-col>
            <v-col md="3" cols="12" class="text-center">
                        <img class="about-img"  src="@/assets/mission6.png" alt="img" />
                         <div v-bind:class="$i18n.locale === 'ar' ?' subboldTxtAr' : 'subboldTxtEn'" > {{ $t('Technology')}}</div>
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' greenTxtAr' : 'greenTxtEn'" > <span v-bind:class="$i18n.locale === 'ar' ?' pinkTxtAr' : 'pinkTxtEn'" >{{ $t('Agility')}}</span> {{ $t('For Update')}} </div>
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" >{{ $t('Technology-txt')}}</div>

                                   </v-col>
             <v-col md="3" cols="12" class="text-center">
            <img class="about-img"  src="@/assets/mission7.png" alt="img" />
                                  <div v-bind:class="$i18n.locale === 'ar' ?' subboldTxtAr' : 'subboldTxtEn'" >{{ $t('Youth')}}</div>
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' greenTxtAr' : 'greenTxtEn'" ><span v-bind:class="$i18n.locale === 'ar' ?' pinkTxtAr' : 'pinkTxtEn'" >{{ $t('Ambition')}}</span> {{ $t('For Leadership')}}</div>
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" >{{ $t('Youth-txt')}}</div>
          </v-col>
           <v-col md="3" cols="12" class="text-center">
            <img class="about-img"  src="@/assets/mission8.png" alt="img" />
<div v-bind:class="$i18n.locale === 'ar' ?' subboldTxtAr' : 'subboldTxtEn'" >{{ $t('Arts')}} </div>
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' greenTxtAr' : 'greenTxtEn'" ><span v-bind:class="$i18n.locale === 'ar' ?' pinkTxtAr' : 'pinkTxtEn'" >{{ $t('Diversity')}}</span> {{ $t('For Creativity')}} </div>
                                                 <div v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" >{{ $t('Arts-txt')}}</div>
          </v-col>
        </v-row>
     </section>
      </v-container>
    </section>

</template>
<script>
export default {
  name:'mission',

}

</script>
<style lang="scss" scoped>
.paddingAll{
  padding: 1% 4%;
}
.sectionMission{
  padding: 4% 0%;
}
.sectionvission{
    position: relative;
}
.pattern{
     background: url("~@/assets/mission-pattern.png");
    background-position: right bottom;
    background-repeat: no-repeat;
  position: absolute;
    width: 100%;
   
    height: 100%;
}
.pink-bg{
  background: #FEF6FA;
  
}

.about-img{
    width: 70%;
}
.areaTxt{
         font-family:"Philosopher-Regular";

}
.greenTxtEn{
    color:#9CCE6E;
             font-family:"Philosopher-Regular";
font-size: 16px;

}
.pinkTxtEn{
        font-family:"Philosopher-Regular";
font-size: 16px;
color:#F167A7;
}
.greenTxtAr{
    color:#9CCE6E;
              font-family: "gess-Light";
font-size: 16px;

}
.pinkTxtAr{
      font-family: "gess-Light";
font-size: 16px;
color:#F167A7;
}
 .subboldTxtEn{
     font-family:"Philosopher-bold";
  font-size: 26px !important;
  color: #000000;

  }
     .subboldTxtAr{
  font-family:"gess-bold" !important;
  font-size: 26px !important;
  color: #000000;

  }

</style>
